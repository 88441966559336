import React, { useEffect, useState, useContext } from 'react';
import Image from 'next/image';
import DecaLink from '../../DecathlonTheme/DecaLink';
import { DeviceContext, deviceTypes } from '@/context/DeviceContext';

const CategoryBannerCards = (props: any) => {
  const { bannerCardsData, categoryChildStyles, parentRef, padding, GAEvent_bannerClick } = props;

  const { name, bigTitle, smallTitle, buttonText, desktopImage, mobileImage, redirectionURL, priority } =
    bannerCardsData;
  const [imageWidth, setImageWidth] = useState(0);

  useEffect(() => {
    const parentWidth = (document.getElementById('category-banner-container') as HTMLElement).clientWidth;
    setImageWidth(parentWidth);
  }, []);

  const deviceType = useContext(DeviceContext).contextDevice;
  const isDesktop = deviceType === deviceTypes.desktop;

  const parentHeight = parentRef?.current?.offsetHeight || 100;

  return (
    <DecaLink
      href={redirectionURL}
      onClick={() => {
        GAEvent_bannerClick(bannerCardsData);
      }}
    >
      <div data-test-id={`floor:category-banner-cards`} className="relative">
        {bigTitle || smallTitle || buttonText ? (
          <div className={`absolute flex flex-col md:gap-2 gap-1 md:mx-12 mx-8 top-[23%] md:top-[25%]`}>
            <h3 className="md:text-36 font-bold text-white">{bigTitle}</h3>
            <p className="md:text-24 font-normal text-white">{smallTitle}</p>
            <div className="w-full">
              {parentHeight - padding >= 110 ? (
                <button className="bg-[#FCE957]  md:text-[15px] text-[8px] md:px-6 md:py-2 px-3 py-1 font-bold rounded-[4px] text-[#000000] uppercase">
                  {buttonText}
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
        <Image
          src={isDesktop ? desktopImage : mobileImage}
          alt={name}
          className="swiper-lazy  w-full h-full"
          width={imageWidth}
          height={isDesktop ? 423 : 105}
          style={categoryChildStyles}
          loading="eager"
        />
      </div>
    </DecaLink>
  );
};

export default CategoryBannerCards;
