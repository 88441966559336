import { GAPageType } from '../GAhelpers';

interface BannerType {
  id: string;
  name: string;
  creative: string;
  floorHeading?: string;
}

interface CategoryBannerType {
  id: string;
  name: string;
  creative_name: string;
  creative_slot: string;
}

/**
 * Function to track the view of banners throughout the website.
 *
 * @param {Object[]} bannerData - An array of data representing the details of the banner containing id, name and banner name.
 */

export const GAEvent_bannerViewPromotion = (bannerData: BannerType[]) => {
  try {
    const eventData = {
      event: 'view_promotion',
      ecommerce: {
        items: bannerData?.map((banner: BannerType) => ({
          id: banner?.id,
          name: banner?.name,
          creative: banner?.creative,
        })),
        'website feature details': bannerData?.map((banner: BannerType) => ({
          'location on page': GAPageType() || '',
          type: banner?.creative,
          name: banner?.name,
        })),
      },
    };

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(eventData);
  } catch {}
};

/**
 * Function to track the view of banners throughout the website.
 *
 * @param {Object[]} bannerData - An array of data representing the details of the banner containing id, name, creative name and slot.
 */

export const GAEvent_categoryBannerViewPromotion = (bannerData: CategoryBannerType[]) => {
  try {
    const eventData = {
      event: 'view_promotion',
      ecommerce: {
        items: bannerData?.map((banner: CategoryBannerType) => ({
          item_id: banner?.id,
          item_name: banner?.name,
          quantity: 1,
          affiliation: 'Decathlon Web Store',
          creative_name: banner?.creative_name,
          creative_slot: banner?.creative_slot,
        })),
      },
    };

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(eventData);
  } catch {}
};

/**
 * This event is triggered when a user clicks on the banner throughout the website
 *
 * @param {Object} bannerData - An object representing the details of the selected banner that contains id, name and banner name.
 */

export const GAEvent_bannerClick = (bannerData: BannerType) => {
  try {
    const eventData = {
      event: 'select_promotion',
      ecommerce: {
        items: {
          id: bannerData?.id,
          name: bannerData?.name,
          creative: bannerData?.floorHeading || bannerData?.creative,
        },
        'website feature details': {
          'location on page': GAPageType() || '',
          type: bannerData?.floorHeading || bannerData?.creative,
          name: bannerData?.name,
        },
      },
    };

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(eventData);
  } catch {}
};

/**
 * This event is triggered when a user clicks on the banner throughout the website
 *
 * @param {Object} bannerData - An object representing the details of the selected banner that contains id, name, creative name and slot.
 */

export const GAEvent_categoryBannerClick = (bannerData: CategoryBannerType) => {
  try {
    const eventData = {
      event: 'select_promotion',
      ecommerce: {
        items: {
          promotion_id: bannerData?.id,
          promotion_name: bannerData?.name,
          creative_name: bannerData?.creative_name,
          creative_slot: bannerData?.creative_slot,
          quantity: 1,
        },
      },
    };

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(eventData);
  } catch {}
};
