import React, { useEffect, useLayoutEffect, useRef } from 'react';
import CategoryBannerCards from './CategoryBannerCards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import classNames from 'classnames';
import DecaButton from '@/src/components/DecathlonTheme/DecaButton';
import VideoBannerCat from './VideoBannerCat';
import DecaLink from '../../DecathlonTheme/DecaLink';
import { GAEvent_categoryBannerClick } from '@/redux/actions/GAEventsActions/globalEvents/GAbannerPromotion';

type CategoryBannerProps = {
  bannerIndex: string;
  data: Array<any>;
  isDesktop: boolean;
  homePage: boolean;
  categoryBannersCallback: Function;
};

const CategoryBanners = (props: CategoryBannerProps) => {
  const { bannerIndex, data, isDesktop, homePage, categoryBannersCallback } = props;
  let currentBannerCards: Array<any> = [];
  const parentRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef();

  useLayoutEffect(() => {
    if (parentRef.current) {
      parentRef.current.style.setProperty('--bullet-color', currentBanner[0]?.carouselIndicatorActive || '#3643BA');
      parentRef.current.style.setProperty(
        '--bullet-color-inactive',
        currentBanner[0]?.carouselIndicatorInactive || '#a19d9d',
      );
      parentRef.current.style.setProperty('--bullet-inactive-opacity', '1');
    }
  }, []);

  useEffect(() => {
    if (props.categoryBannersCallback) {
      const promoData = bannerCardsData
        .filter((banner) => banner?.desktopImage && banner?.mobileImage)
        .map((banner) => {
          return {
            id: banner?.id,
            name: banner?.name,
            creative_name: banner?.creativeName,
            creative_slot: banner?.creativeSlot,
          };
        });
      if (promoData?.length > 0) categoryBannersCallback(promoData);
    }
  }, []);

  const currentBanner: any = data.filter((element: any) =>
    isDesktop ? element.floorPositionWeb === bannerIndex : element.floorPosition === bannerIndex,
  );
  if (currentBanner[0]?.type === 'defaultBanners') {
    currentBannerCards = currentBanner[0].categoryBannerCards;
  }
  if (currentBanner[0]?.type === 'categoryBanners') {
    currentBannerCards = currentBanner[0].categoryBannerImgSection;
  }

  let bannerCardsData = currentBannerCards.map((banner, index) => {
    return {
      id: banner?.sys?.id,
      name: banner?.fields?.name,
      bigTitle: banner?.fields?.bigTitle,
      smallTitle: banner?.fields?.smallTitle,
      buttonText: banner?.fields?.ctaText,
      desktopImage: banner?.fields?.catImageDesktopUrl,
      mobileImage: banner?.fields?.catImageMobileUrl,
      wcmsVideoBanner: banner?.fields?.catVideoBanner,
      videoBannerUrl: banner?.fields?.catVideoBannerUrl,
      redirectionURL: banner?.fields?.imageHyperLink,
      hyperlinkTracking: banner?.fields?.hyperlinkTracking,
      creativeName: currentBanner[0]?.name,
      creativeSlot: index,
    };
  });

  // Filter out empty banner objects
  bannerCardsData = bannerCardsData.filter(
    (banner) => banner?.name && (banner?.desktopImage || banner?.videoBannerUrl),
  );

  const hasVideoBanner = bannerCardsData.some((banner) => banner?.videoBannerUrl);

  const categoryContainerStyles: any = {
    paddingBottom: `${currentBanner[0]?.paddingBottom || 0}px`,
    paddingLeft: `${currentBanner[0]?.paddingLeft || 0}px`,
    paddingRight: `${currentBanner[0]?.paddingRight || 0}px`,
    paddingTop: `${currentBanner[0]?.paddingTop || 0}px`,
    background: currentBanner[0]?.catBackgroundImageDesktop
      ? `url(${currentBanner[0]?.catBackgroundImageDesktop})`
      : currentBanner[0]?.catBackgroundColour,
  };

  const categoryChildStyles: any = {
    borderRadius: `${currentBanner[0]?.borderRadiusTopLeft || 0}px ${currentBanner[0]?.borderRadiusTopRight || 0}px 
    ${currentBanner[0]?.borderRadiusBottomRight || 0}px ${currentBanner[0]?.borderRadiusBottomLeft || 0}px`,
  };

  // GA4 banner click
  const GAEvent_bannerClick = (banner: any) => {
    const promoData = {
      id: banner?.id,
      name: banner?.name,
      creative_name: banner?.creativeName,
      creative_slot: banner?.creativeSlot,
    };

    GAEvent_categoryBannerClick(promoData);
  };

  return (
    <div
      ref={parentRef}
      data-test-id="floor:category-banner-container"
      className={classNames('w-full h-auto md:mb-10 my-1', {
        'md:w-[calc(100%-33px)]': !homePage,
        'w-full': homePage,
      })}
      id="category-banner-container"
      style={categoryContainerStyles}
    >
      <Swiper
        modules={[Autoplay, Pagination]}
        pagination={
          currentBanner[0]?.carouselIndicator
            ? {
                clickable: true,
                dynamicBullets: true,
              }
            : false
        }
        autoplay={
          !hasVideoBanner
            ? {
                delay: currentBanner[0]?.carouselInterval ? parseInt(currentBanner[0]?.carouselInterval) * 1000 : 3500,
                disableOnInteraction: true,
              }
            : false
        }
        loop={true}
      >
        {bannerCardsData?.map((banner, index) => (
          <SwiperSlide key={index} className="relative w-full h-full">
            {banner?.videoBannerUrl ? (
              <DecaLink
                href={banner?.redirectionURL}
                onClick={banner?.hyperlinkTracking}
                target={banner?.redirectionURL ? '_blank' : ''}
              >
                <VideoBannerCat GAEvent_bannerClick={GAEvent_bannerClick} banner={banner} />

                {banner?.buttonText ? (
                  <div className="videoCenterBtn ">
                    <DecaButton
                      type="submit"
                      className={` px-5 py-2 md:py-3 mr-4 overflow-hidden text-white transition duration-300 bg-blue-400 bottom-6 rounded-3xl max-w-100 hover:bg-blue-300 uppercase font-condensed text-8 font-medium md:text-16 md:shadow-md btn-default btn-md btn`}
                    >
                      {banner?.buttonText}
                    </DecaButton>
                  </div>
                ) : null}
              </DecaLink>
            ) : banner.desktopImage && banner.mobileImage ? (
              <CategoryBannerCards
                swiperRef={swiperRef}
                parentRef={parentRef}
                GAEvent_bannerClick={GAEvent_bannerClick}
                padding={parseInt(currentBanner[0]?.paddingBottom || 0) + parseInt(currentBanner[0]?.paddingTop || 0)}
                key={banner?.name}
                categoryChildStyles={categoryChildStyles}
                bannerCardsData={banner}
              />
            ) : null}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CategoryBanners;
