import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
const VideoBannerSkeleton = dynamic(() => import('./VideoBannerSkeleton'));

interface BannerProps {
  videoBannerUrl: string;
  name: string;
}

const VideoBannerCat: React.FC<{ banner: BannerProps; GAEvent_bannerClick: Function }> = ({
  banner,
  GAEvent_bannerClick,
}) => {
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [posterSrc, setPosterSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideoData = async () => {
      if (!banner?.videoBannerUrl) return;

      const videoId = getVideoIdFromUrl(banner?.videoBannerUrl);
      if (!videoId) return;

      try {
        const response = await axios.get(
          `https://edge.api.brightcove.com/playback/v1/accounts/3415345270001/videos/${videoId}`,
          {
            headers: {
              'BCOV-Policy':
                'BCpkADawqM32Y1B_NO6aXNzGybLomK5OeXmXGhcROkEX814--h00Ayld41YZ8pOxJlT3-chmtQW20tzABw-D6EYau3KUqkMBqqLwPBdN_g1JFoZNuuliJFk7DKcJJxvy4YDQuA1e1eqFSOmW',
            },
          },
        );

        const mp4Source = response.data.sources.find((source: { container: string }) => source.container === 'MP4');

        const posterSource = response.data.poster;

        if (mp4Source) {
          const httpsVideoSrc = mp4Source.src.replace(/^http:\/\//i, 'https://');
          setVideoSrc(httpsVideoSrc);
        } else {
          setVideoSrc(null);
        }

        if (posterSource) {
          setPosterSrc(posterSource);
        }

        setLoading(false);
      } catch (error) {
        console.error(`Error fetching video data for videoId ${videoId}:`, error);
        setLoading(false);
      }
    };

    fetchVideoData();
  }, [banner.videoBannerUrl]);

  const getVideoIdFromUrl = (videoLink: string): string | null => {
    try {
      const urlObj = new URL(videoLink);
      const videoId = urlObj.searchParams.get('videoId');
      return videoId;
    } catch (error) {
      console.error('Invalid video URL:', error);
      return null;
    }
  };

  if (loading) {
    return <VideoBannerSkeleton />;
  }

  if (loading && posterSrc) {
    return (
      <img
        onClick={() => {
          GAEvent_bannerClick(banner);
        }}
        src={posterSrc}
        alt={`${banner.name} Poster`}
        className="mx-auto"
      />
    );
  }

  return videoSrc ? (
    <div
      onClick={() => {
        GAEvent_bannerClick(banner);
      }}
      className="relative"
    >
      <video preload="none" src={videoSrc} autoPlay loop playsInline muted className="mx-auto" />
    </div>
  ) : (
    <div>No video available</div>
  );
};

VideoBannerCat.propTypes = {
  banner: PropTypes.shape({
    videoBannerUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default VideoBannerCat;
